import {useTranslation} from "react-i18next";
import {Spinner} from "react-bootstrap";

export const OTPModal = ({OTP, handleChangeOTP, onClose, verifyOTP, email, isLoading}) => {
    const {t} = useTranslation();

    return (
        <div className="modal-body">
      <span
          className="material-symbols-outlined d-block mx-auto text-center"
          style={{fontSize: 90}}
      >
        mail
      </span>
            <p className="text-center mt-2">
                {t("Verification code has been sent to")} <br/> <b>{email}</b>
            </p>
            <input
                className="form-control mx-auto w-75 mt-4"
                value={OTP}
                placeholder="OTP"
                onChange={handleChangeOTP}
            />
            <div className="d-flex justify-content-center mt-5">
                <button
                    type="button"
                    className="btn btn-outline-primary btn-lg px-5  d-block"
                    onClick={onClose}
                >
                    {t("Cancel")}
                </button>
                <button
                    type="button"
                    className="btn btn-primary btn-lg px-5 ml-3  d-block"
                    onClick={() => !isLoading && verifyOTP("email")}
                >
                    {isLoading ? (
                        <Spinner animation="border" variant="primary"/>
                    ) : (t("Verify"))}
                </button>
            </div>
        </div>
    )
}
