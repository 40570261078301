import {useEffect} from "react";

let TradingView = window.TradingView;

const TradingViewTdChartHuobi = () => {
    const isColorReference = localStorage.getItem("colorReference") !== null;
    const colorReference = isColorReference
        ? localStorage.getItem("colorReference").split(",")
        : ["Green Up", "Red Down"];
    let HideBar = false;
    let Height = 610;
    const coins = [
        "BTC",
        "ETH",
        "BNB",
        "XRP",
        "ADA",
        "SOL",
        "MATIC",
        "DOGE",
        "SHIB",
        "AVAX",
        "DOT",
        "ATOM",
        "TRX",
        "UNI",
        "WBTC",
        "ETC",
        "LTC",
        "XTZ",
    ];

    useEffect(() => {
        if (window.innerWidth < 990) {
            HideBar = true;
            Height = 400;
        }
        new TradingView.widget({
            container_id: "technical-analysis",
            width: "100%",
            height: Height,
            "symbol": "CRYPTO:BTCUSD",
            "interval": "D",
            "timezone": "Etc/UTC",
            "theme": "light",
            "style": "3",
            "locale": "en",
            "toolbar_bg": "#f1f3f6",
            "enable_publishing": false,
            "allow_symbol_change": true
        })

    }, []);
    return <div className="d-block" id="technical-analysis"></div>;
};

export default TradingViewTdChartHuobi;
