import { Dropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";

export const SearchFiled = ({ searchValue, handleSearch }) => {
  const { t } = useTranslation();

  return (
    <Dropdown.Item>
      <div
        style={{ position: "sticky", top: "-0.5rem" }}
        className="search-wrapper"
      >
        <input
          value={searchValue}
          placeholder={t("Search")}
          onChange={handleSearch}
          className="search-input"
        />
        <span className="material-symbols-outlined search-icon">search</span>
      </div>
    </Dropdown.Item>
  );
};
