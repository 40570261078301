import {getLoggedIn} from "../../../utils";
import ApiService from "../../../services/apiService";
import {useTranslation} from "react-i18next";
import {useState} from "react";
import {Spinner} from "react-bootstrap";

export const ConfirmDeletionModal = ({onClose, result}) => {
    const {t} = useTranslation();
    const [isLoading, setIsLoading] = useState(false);


    const deleteTradingAccount = async () => {
        setIsLoading(true);
        const data = getLoggedIn();
        const response = await ApiService.deleteSubAccount({subAcct: data[5]});
        if (response.status === 200) {
            setIsLoading(false);
            onClose();
        }
    };

    return (
        <div className="container p-4">
            <div className="d-flex flex-column align-items-center justify-content-center">
                <h4>{t("account.settings.Trading Account")}</h4>
                <h4>{t("account.settings.Delete Confirmation")}</h4>
            </div>
            <div className="red-text mt-lg-3 mb-4" style={{fontSize: 14}}>
                <p>{result.length > 0 ? t("account.community.Wrong Pin-Code") : t("account.settings.Your deletion request has started")}</p>
            </div>
            <div className="d-flex justify-content-center mt-5">
                {result.length > 0 ?
                    <div
                        className="btn btn-continue"
                        style={{width: "150px", height: "40px"}}
                        onClick={onClose}
                    >
                        {t("OK")}
                    </div>
                    :
                    <div
                        className="btn btn-continue"
                        style={{width: "150px", height: "40px"}}
                        onClick={deleteTradingAccount}
                    >
                        {isLoading ? (
                            <Spinner animation="border" variant="primary"/>
                        ) : t("Continue")}
                    </div>
                }
            </div>
        </div>
    )
}
