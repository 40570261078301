import {getLoggedIn, getTheme} from "../../../utils";
import {Divider} from "@mui/material";
import {useTranslation} from "react-i18next";
import {Modal, Spinner} from "react-bootstrap";
import {useContext, useEffect, useState} from "react";
import ApiService from "../../../services/apiService";
import {InputOTPModal} from "./InputOTPModal";
import {FaildAttemptModal} from "./FaildAttemptModal";
import {SuccessfullChangeModal} from "./SuccessfullChangeModal";
import {NavLink, useNavigate} from "react-router-dom";
import {FTFTexContext} from "../../../App";

export const ChangePasswordSecurity = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const blockedTime = localStorage.getItem("blockPasswordTime");
    const now = new Date().getTime();
    const email = getLoggedIn()[4];
    const ftftexValue = useContext(FTFTexContext);
    const [isMobile, setIsMobile] = useState(false);
    const [failedAttempts, setFailedAttempts] = useState(0);
    const [showOTPModal, setShowOTPModal] = useState(false);
    const [showFaildAttemptModal, setShowFaildAttemptModal] = useState(false);
    const [suceessfullChangeModal, setSuceessfullChangeModal] = useState(false);
    const [currentPassword, setCurrentPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmNewPassword, setConfirmNewPassword] = useState("");
    const [passwordError, setPasswordError] = useState([]);
    const [isDisabled, setIsDisabled] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [DialingCode, setDialingCode] = useState(ApiService.DialingCode);
    const [showPassword, setShowPassword] = useState(false);
    const date1 = new Date(parseInt(blockedTime));
    const date2 = new Date(now);
    const diffInMs = date1 - date2;
    const minutes = Math.floor(diffInMs / 60000);
    const passwordRequirements = [
        t("account.security.Password must be at least 8 characters"),
        t("account.security.Password must contain at least one small letter"),
        t("account.security.Password must contain at least one capital letter"),
        t("account.security.Password must contain at least one digit"),
        t("account.security.Password must contain at least one special character")
    ];

    useEffect(() => {
        setIsMobile(ftftexValue[0].isMobile);
    }, [ftftexValue[0].isMobile]);

    useEffect(() => {
        if (blockedTime && now < blockedTime) {
            setIsDisabled(true);
        } else {
            setIsDisabled(false);
            localStorage.removeItem("blockPasswordTimePassword");
        }
    }, []);

    const checkPassword = (password) => {
        const passwordError = [];
        if (password.length < 8) {
            passwordError.push(t(
                "account.security.Password must be at least 8 characters"
            ));
        }
        if (password.search(/[a-z]/) < 0) {
            passwordError.push(t(
                "account.security.Password must contain at least one small letter"
            ));
        }
        if (password.search(/[A-Z]/) < 0) {
            passwordError.push(t(
                "account.security.Password must contain at least one capital letter"
            ));
        }
        if (password.search(/[0-9]/) < 0) {
            passwordError.push(t(
                "account.security.Password must contain at least one digit"
            ));
        }
        if (password.search(/[!@#$%^&*]/) < 0) {
            passwordError.push(t(
                "account.security.Password must contain at least one special character"
            ));
        }
        if (currentPassword.length === 0) {
            passwordError.push(t("account.security.Enter your current password"));
        }
        if (confirmNewPassword !== newPassword) {
            passwordError.push('New Password and Confirm Password are not same');
        }
        setPasswordError(passwordError);
        return passwordError;
    };

    const changePassword = async () => {
        const error = checkPassword(newPassword);
        const email = JSON.parse(localStorage.getItem("usr"));
        if (failedAttempts === 3) {
            const oneHour = new Date(new Date().getTime() + 60 * 60 * 1000);
            localStorage.setItem("blockPasswordTimePassword", oneHour.getTime().toString());
            setIsDisabled(true);
        } else {
            if (error.length === 0) {
                setIsLoading(true);
                const sendEmailResponse = await ApiService.ForgotPassword(
                    {email: email[4]},
                    "email",
                    DialingCode.dialCode
                );
                if (sendEmailResponse.status === 200) {
                    setIsLoading(false);
                    setShowOTPModal(true);
                }
            }
        }
    };

    const handleChangePassword = () => {
        localStorage.clear();
        window.location.reload();
        navigate("/login");
        navigate(0);
    }


    return (
        <>
            <div className="container mt-4 mb-4 p-4">
                <div className="pb-2 light-border-bottom">
                    <h4 className={`${getTheme() === 'dark' ? 'sub-title-dark' : 'sub-title'}`}>
                        {t("Change Password")}
                    </h4>
                    <Divider/>
                </div>
                <div>
                    <div className={`row py-2 mt-2 ${isMobile ? 'col-12' : 'col-10'}`}>
                        <div className={isMobile ? "col-5": "col-4"}>
                            <p>{t("account.security.Current Password")}</p>
                        </div>
                        <div className="col-7">
                            <div className="d-flex flex-column">
                                <input
                                    className="form-control w-100"
                                    type={showPassword ? "text" : "password"}
                                    disabled={isDisabled}
                                    value={currentPassword}
                                    onChange={(e) => setCurrentPassword(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className={`row py-2 mt-2 ${isMobile ? 'col-12' : 'col-10'}`}>
                        <div className={isMobile ? "col-5": "col-4"}>
                            <p>{t("account.security.New Password")}</p>
                        </div>
                        <div className="col-7">
                            <div className="d-flex">
                                <input
                                    className="form-control w-100"
                                    type={showPassword ? "text" : "password"}
                                    disabled={isDisabled}
                                    value={newPassword}
                                    onChange={(e) => {
                                        setNewPassword(e.target.value);
                                        checkPassword(e.target.value);
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className={`row py-2 mt-2 align-items-center ${isMobile ? 'col-12' : 'col-10'}`}>
                        <div className={isMobile ? "col-5": "col-4"}>
                            <p>{t("account.security.Confirm New Password")}</p>
                        </div>
                        <div className="col-7">
                            <div className="d-flex flex-row">
                                <input
                                    className="form-control w-100"
                                    type={showPassword ? "text" : "password"}
                                    disabled={isDisabled}
                                    value={confirmNewPassword}
                                    onChange={(e) => setConfirmNewPassword(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className={isMobile ? 'ml-3' : "d-flex flex-row align-items-center show-password"}>
                            <input
                                type="checkbox"
                                id="exampleCheck1"
                                checked={showPassword}
                                onChange={() => setShowPassword(!showPassword)}
                            />
                            <label className="show-password-label"
                                   htmlFor="exampleCheck1">{t("account.security.Show Password")}</label>
                        </div>
                    </div>
                    {confirmNewPassword.length > 0 && newPassword !== confirmNewPassword &&
                        <span
                            className="align-self-end helper-text ml-3">New password and confirm are not the same</span>}
                    {currentPassword.length === 0 && newPassword.length > 0 && newPassword === confirmNewPassword &&
                        <span
                            className="align-self-end helper-text ml-3">{t("account.security.Enter your current password")}</span>}
                    <div className="strength">
                        <div className={`square ${passwordError.length === 5 || passwordError.length >= 2 && 'low'} ${passwordError.length <= 1 && newPassword.length > 0 && 'middle'} ${newPassword.length >= 12 && 'strong'} `}/>
                        <div className={`square ${passwordError.length <= 1 && newPassword.length > 0 && 'middle'} ${newPassword.length >= 12 && 'strong'}`}/>
                        <div className={`square ${newPassword.length >= 12 && 'strong'}`}/>
                        <div className="ml-3">{t("Password Strength")}</div>
                    </div>
                    <div className="password-required-wrapper">
                        <div>{t("Password Requirements")}</div>
                        <div className="mt-4">
                            {passwordRequirements.map((item, index) => (
                                <div key={index} className="password-required">
                                    {passwordError.includes(item) ? (
                                        <div className="dot-fail">
                                    <span className="material-symbols-outlined"
                                          style={{fontSize: '17px', color: 'whitesmoke'}}>close</span>
                                        </div>
                                    ) : (
                                        <div className="dot-verify">
                                    <span className="material-symbols-outlined"
                                          style={{fontSize: '18px', color: 'whitesmoke'}}>
                                    done
                                    </span>
                                        </div>
                                    )}
                                    <div className="mr-2">
                                        {item}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    {isDisabled ? (
                        <span className="align-self-center mt-4">
            {t("account.security.You can change your password only after")}
                            {` ${minutes} minutes`}
          </span>
                    ) : (
                        <div className="d-flex mb-2 mt-4">
                            <div className={`btn save-btn ${isMobile ? 'confirm-btn-mobile' : 'confirm-btn'}`} onClick={changePassword}>
                                {isLoading ? (
                                    <Spinner animation="border" variant="primary"/>
                                ) : (
                                    t("Confirm")
                                )}
                            </div>
                        </div>
                    )}
                </div>
                <button className="btn btn-primary px-5 d-block mt-5 back-btn">
                    <NavLink to={"/account/security"}>{t("Back")}</NavLink>
                </button>
            </div>
            <Modal
                show={showOTPModal}
                onHide={() => setShowOTPModal(false)}
                centered
            >
                <InputOTPModal onClose={() => setShowOTPModal(false)} email={email} newPassword={newPassword}
                               failedAttempts={failedAttempts} setFailedAttempts={setFailedAttempts}
                               setShowFaildAttemptModal={setShowFaildAttemptModal}
                               setSuceessfullChangeModal={setSuceessfullChangeModal}/>
            </Modal>
            <Modal
                show={showFaildAttemptModal}
                onHide={() => setShowFaildAttemptModal(false)}
                centered
            >
                <FaildAttemptModal handleClose={() => setShowFaildAttemptModal(false)} show={showFaildAttemptModal}
                                   faildAttemps={failedAttempts}/>
            </Modal>
            <Modal show={suceessfullChangeModal} onHide={() => setSuceessfullChangeModal(false)} centered>
                <SuccessfullChangeModal handleClose={handleChangePassword}/>
            </Modal>
        </>
    )
}
