import {useState} from "react";
import ApiService from "../../../services/apiService";
import {getLoggedIn} from "../../../utils";
import {OTPModal} from "../../Common/OTPModal/OTPModal";

export const DeleteAccountOTP = ({onClose, setResultModal, setResultMessage}) => {
    const [OTP, setOTP] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const userId = localStorage.getItem("userId");
    const email = getLoggedIn()[4];

    const handleChangeOTP = (e) => {
        setOTP(e.target.value);
    };

    const verifyOTP = async () => {
        setIsLoading(true);
        const data = {
            email: email,
            pinCode: OTP,
            service: "change_password",
            userId: userId,
        };
        const responseVerify = await ApiService.VerifyUser(data);
        if (responseVerify.data.Message === "Wrong Pin-Code") {
            setResultMessage("account.community.Wrong Pin-Code");
            setIsLoading(false);
            onClose();
            setResultModal();
        } else {
            onClose();
            setResultModal();
            setResultMessage("");
        }
    };

    return (
        <OTPModal verifyOTP={verifyOTP} OTP={OTP} handleChangeOTP={handleChangeOTP} onClose={onClose} email={email}
                  isLoading={isLoading}/>
    );
}
