import "./index.scss";
const ServiceTerms = () => {
  return (
    <>
      <h1>FTFTex Terms of Service</h1>
      <h3>1. TERMS OF USE</h3>
      <p>
        Welcome to FTFTex!
        <br />
        You agree and understand that by signing up to use an account by FTFTex,
        you agree to enter this user agreement (the "Agreement"). The Agreement
        is between you and FTFTex.
        <br />
        FTFTex provides Cryptocurrency market data and aggregate trading
        information platform to individuals and institutions ("we", "us", "our"
        and "FTFTex"). We refer to the Cryptocurrency market data providing and
        aggregate trading information services as the "Services.” These terms
        and conditions ("Terms") apply to your ("user," "you," "your") use of
        the Services by way of FTFTex [or any of our associated websites], APIs,
        or mobile applications (collectively, the "Site").
        <br />
        By clicking "I Agree," signing up to use an account, or otherwise using
        the site, you acknowledge that you have read, understood, and agree to
        be bound by the Terms, including the risks and disclosures as well as
        our AML & Compliance Policy and Privacy Policy. If you do not agree with
        any of the Terms, your sole recourse is to use or stop using the Site or
        the Services.
        <br />
        FTFTex reserves the right to amend the Terms from time to time based on
        discretion, at any time by posting an updated version on the site. We
        encourage you to check the Terms regularly for any amendments to them.
        If you have signed up to use an account, we will send an email
        notification of any modifications to the Terms to your registered email
        address. Your continued use of the site or the Services will constitute
        your consent to the amended Terms.
        <br />
      </p>
      <h3>2. DISCLAIMER</h3>
      <p>
        FTFTex, under any condition, does not and cannot guarantee the value of
        a Digital Asset. You acknowledge and agree that the value of a Digital
        Asset is subject to high levels of volatility and that purchasing,
        selling, and holding Digital Assets involves high risk. You should be
        aware that the value of a Digital Asset can change rapidly, decrease,
        and potentially fall to zero. You should carefully consider whether to
        purchase, sell, and hold Digital Assets. Please do not use the Services
        if you are not able to bear the financial risk involved. See Section 10
        below to explain how our liability is limited to the extent permitted by
        law.
        <br />
      </p>
      <h3>3. OUR SERVICES</h3>
      <p>
        3.1. Eligibility
        <br />
        To be eligible to create an account with FTFTex and use any of the
        services (“Services”), you must meet and continue to meet the following
        criteria:
        <br />
        1.If you are an individual or of other legal age according to your
        relevant jurisdiction;
        <br />
        2. If you are using the Services on behalf of a legal entity, you must
        be duly authorized to act on behalf of the legal entity, and the legal
        entity must be correctly formed or incorporated;
        <br />
        3. You reside in a country where the Services are available;
        <br />
        4. You have the legal power to consent to these Terms;
        <br />
        5. You will not use any of the Services if the laws of your country
        prohibit you from doing so;
        <br />
        6. You will provide all information (including identity information)
        required by us to open your account or at any time afterward that we
        need to meet our obligations under applicable laws.
        <br />
        If at any time you do not meet any of these criteria, you must stop
        using the Site and the Services. We can close or suspend your account at
        any time where you do not meet these criteria.
        <br />
        3.2. Providing Cryptocurrency Data and Market Information Services
        <br />
        The specific services of FTFTex are such as real-time market
        information, professional K-line, provision of micro and macro-economic
        news, aggregate transaction, hot spots, smart stock staring, analysis of
        analysts, and data tracking. Users must use the services provided by
        FTFTex under the terms of service. FTFTex does not promise to inform
        users in advance of service start, change or end.
        <br />
        3.3 Aggregate Trading Information Platform
        <br />
        FTFTex Provides Aggregate Trading Information Services.
        <br />
        3.4. Digital Asset Services
        <br />
        The following services (the “Digital Asset Services”) may be provided to
        you by FTFTex:
        <br />
        1. Compound digital wallet hosted digital by our service provider,
        enabling you to store, transfer and manage your balances of accepted
        Digital Assets; and
        <br />
        2. A Digital Asset exchange service (“Digital Asset Exchange”) enabling
        you to buy and sell Digital Assets.
        <br />
        4. FEES
        <br />
        You agree that FTFTex may amend its Fee Schedule at any time and can
        inform you of any alterations made in any manner determined as
        appropriate, including posting the updated list of fees on the page of
        the website. In the absence of an objection being raised by you within
        thirty (30) days, the alterations are deemed to have been approved by
        you.
        <br />
      </p>
      <h3>5. YOUR ACCOUNT</h3>
      <p>
        5.1. Opening of an Account
        <br />
        To use the Services, you will need to register for a verified FTFTex
        account ("FTFTex Account") by providing your full name, address, email
        address, date of birth, government and/or passport number, password,
        telephone number, and accepting the terms of this Agreement, AML &
        Compliance Policy, and Privacy Policy. You are fully responsible for all
        activity that occurs under your FTFTex Account. We may, at our sole
        discretion in light of not satisfying the requisite requirements, refuse
        to open a FTFTex Account or provide a service for you.
        <br />
        5.2. Identity Verification
        <br />
        You agree to provide us with the information we request for identity
        verification and the detection of money laundering, terrorist financing,
        fraud, or any other financial crime, as set out in Appendix 2
        (Verification Procedures and Limits), and grant us the right to keep a
        record of the information. You will need to complete specific
        verification procedures before you are permitted to start using the
        FTFTex Services and to access particular FTFTex Services. The limits
        that apply to your use of the FTFTex Services may be altered due to
        information collected on an ongoing basis.
        <br />
        The information we request may include certain personal information
        including, but not limited to, your full name, your residence address,
        your registered mobile number, valid email address, your date of birth,
        your passport and national ID numbers, and expiry dates. You confirm
        that any information pertaining to verification provided by you is
        accurate.
        <br />
        Additionally, you consent to keep us updated if any of the information
        you provided changes. All of the information provided to us will be
        treated according to our Privacy Policy. You give us authority to make
        inquiries, directly or through third parties, that we deem necessary to
        confirm your identity and protect you and/or us from financial crime and
        subsequently take action where necessary. When carrying out these
        inquiries, your information may be disclosed to the relevant financial
        crime and monetary agencies. Additionally, we may require you to wait
        for 1-3 working days after completing a transaction (but in any event no
        longer than four working days) before permitting you to use additional
        Services and/or before allowing you to transact a certain volume limits.
        <br />
        5.3. Third-party Access
        <br />
        Suppose you grant express permission to a third party to access or
        connect to your FTFTex Account. In that case, you acknowledge that
        granting permission to a third party to take specific actions on your
        behalf does not relieve you of your responsibilities under this
        Agreement. You are fully responsible for all acts or omissions of any
        third party with access to your FTFTex Account, and any action of such a
        third party shall be considered an action authorized by you. Further,
        you acknowledge and agree that you will not hold FTFTex responsible for
        and indemnify FTFTex from any liability arising out of or related to any
        act or omission of any third-party with access to your FTFTex Account.{" "}
        <br />
      </p>
      <h3>6. USE OF SERVICES</h3>
      <p>
        6.1. Purchase and Sell Digital Assets
        <br />
        To carry out a Digital Asset Transaction, you must follow the relevant
        instructions on the site. You authorize us to debit your E-Wallet before
        delivering Digital Assets to your Digital Asset Wallet. You may sell
        Digital Assets. You authorize us to debit your Digital Asset when
        appropriate. <br />
        6.2. Revocation
        <br />
        When you give us instructions to purchase or sell Digital Assets, you
        cannot withdraw your consent to that Digital Asset transaction.
        <br />
        6.3. Unauthorized and Incorrect Transactions
        <br />
        Where a purchase or sale of a Digital Asset is initiated from your
        E-Wallet using your credentials, we will assume that you authorized such
        a transaction unless you notify us otherwise. If you believe that such a
        transaction using your E-Wallet has been carried out without your
        authorization, or if you have reason to believe that a transaction using
        your E-Wallet has been incorrectly carried out or is incomplete, you
        must contact us as soon as possible. It is important that you regularly
        check your FTFTex wallet balance and your transaction history to ensure
        that any unauthorized or incorrect transactions are identified and
        notified to us at the earliest opportunity.
        <br />
        During any investigation of any actual or potential unauthorized or
        incorrect transaction, we reserve the right to suspend your account to
        avoid further losses.
        <br />
        6.4. Redeeming E-Money Deposit Orders
        <br />
        You may redeem all or part of your funds held in your E-Wallet at any
        time by selecting the appropriate option on the site and following the
        instructions. Before redeeming your funds from your E-Wallet, we may
        conduct checks for the purposes of preventing fraud, money laundering,
        terrorist financing, and other financial crimes. This may mean you are
        prevented or delayed from withdrawing funds until those checks are
        completed to our reasonable satisfaction in order to comply with our
        regulatory requirements.
        <br />
        6.5. Account Information
        <br />
        You will be able to see the balances of E-Wallet and Digital Assets.
        <br />
        6.6. Refund Rights
        <br />
        Unauthorized Transactions
        <br />
        Suppose an Unauthorized Transaction occurs as a result of our failure.
        In that case, we will refund you the amount of that transaction by no
        later than the end of the next business day after becoming aware of the
        Unauthorized Transaction and restore your E-Wallet to the state it would
        have been in had the Unauthorized Transaction not taken place. <br />
        Generally, you will be liable for losses incurred where: <br />
        1. The Unauthorized Transaction arises from the use of lost or stolen
        credentials when you have failed to keep the login details of your
        E-Wallet account secure), and
        <br />
        2. If you are fraudulent, or you intentionally or negligently fail to
        carry out your obligations under this Agreement, and this results in the
        Unauthorized Transactions (for example, you deliberately share your
        email and password with a third-party or are grossly negligent in
        keeping your email and password secure), you will be liable for all
        resultant losses as a result of any such Unauthorized Transactions.
        <br />
        Where there is a dispute between us and you regarding whether or not a
        transaction is an unauthorized transaction, we may temporarily credit
        your E-Wallet while we settle the dispute. Where we determine that the
        transaction was authorized, we may reverse that credit and correct
        errors made in any statement of your E-Wallet without prior notice to
        you. However, please note that during this period, your E-Wallet may be
        temporarily locked to avoid further Unauthorized Transactions. You will
        also be liable to us (as a debt) for any funds you have transferred,
        which were temporarily credited to your E-Wallet.
        <br />
        Incorrect Transactions <br />
        Should an error or action emanating from FTFTex result in an Incorrect
        Transaction, we will refund your FTFTex wallet with the transaction
        amount without undue delay and endeavor to provide you with sufficient
        notice. Should an Incorrect Transaction result in you incurring charges
        and/or interest, we will pay for those charges and/or interest. Should
        you initiate any Incorrect Transaction, we shall, on your request, try
        to trace such a transaction.
        <br />
      </p>
      <h3>7. DIGITAL ASSET SERVICES</h3>
      <p>
        7.1. In General
        <br />
        Your Digital Asset Wallet enables you to send, store, and receive
        Digital Assets from third parties by giving instructions through the
        site (each transaction a "Digital Asset Transaction"). The Digital Asset
        Service enables you to:
        <br />
        (1) Buy Digital Assets
        <br />
        (2) Sell Digital Assets stored in your Digital Asset Wallet and receive
        E-funds.
        <br />
        7.2. Supported Digital Assets
        <br />
        Under no circumstances should you attempt to use your FTFTex Account to
        store, send, or receive Digital Assets in any form that we do not
        support. We assume no responsibility or liability in connection with any
        attempt to use your FTFTex Account for Digital Assets that we do not
        support.
        <br />
        7.3. Digital Asset Transaction Fulfillment
        <br />
        FTFTex will make reasonable efforts to fulfill all purchases of Digital
        Assets, but under some circumstances, we may not be able to do so.
        Should this be the case, we will notify you and seek your approval to
        reattempt the purchase at the current Exchange Rate (Section 7.6).
        <br />
        7.4. Exchange Rates
        <br />
        Each purchase or sale of a Digital Asset is also subject to the Exchange
        Rate for the given transaction.
        <br />
        7.5. Authorizations; Reversals; Cancellations
        <br />
        FTFTex will be authorized to initiate a Digital Asset Transaction, and
        the associated Conversion and/or Exchange Fees will be applied. Any
        transaction that is marked as complete or pending cannot be cancelled or
        reversed. In the case where you have insufficient E-Funds in your
        E-Wallet, your transaction will be immediately rejected.
        <br />
        7.6. Digital Asset Wallet Storage
        <br />
        ftftex.com securely stores most of the clients’ Digital Assets in
        Multi-signature wallets that are encrypted and protected using
        Two-factor Authentication, locked withdrawal addresses, IP whitelisting,
        redundantly secured recovery phases and exchange security audits.
        <br />
      </p>
      <h3>8. SUSPENSION, TERMINATION, AND CANCELLATION</h3>
      <p>
        8.1. Suspension, Termination, and Cancellation
        <br />
        FTFTex may: (a) refuse to complete, block, cancel or reverse a
        transaction authorized by you even after funds have been debited from
        your FTFTex account; or (b) restrict, suspend, or terminate your access
        to any FTFTex services; or (c) deactivate or cancel your FTFTex account
        with immediate effect for any reason, including but not limited to:
        <br />
        1.We need to do so to protect our reputation and goodwill;
        <br />
        2.We are required to do so by applicable law and regulation or any other
        legal authority to which we are subject in any jurisdiction, including a
        court order;
        <br />
        3. We reasonably suspect you of acting in breach of the Terms;
        <br />
        4. We reasonably suspect you have breached our Policies (as set out in
        Appendix 1);
        <br />
        5. We have concerns that a transaction is erroneous or about the
        security of your FTFTex Account or we suspect the FTFTex Services are
        being used in a fraudulent or unauthorized manner;
        <br />
        6. We suspect money laundering, terrorist financing, fraud, or any other
        financial crime is being committed;
        <br />
        7. Use of your FTFTex Account is subject to any pending litigation,
        investigation, or government proceeding and/or we perceive a heightened
        risk of legal or regulatory non-compliance associated with your FTFTex
        Account activity; and/or
        <br />
        8. You take any action that may circumvent our controls, such as opening
        multiple FTFTex accounts or abusing promotions that we may offer from
        time to time.
        <br />
        We may also refuse to complete or block, cancel or reverse a transaction
        you have authorized where there are insufficient E-Funds in your
        E-Wallet or Digital Assets in Your Digital Asset Wallet to cover the
        transaction and (where applicable) associated fees at the time that we
        receive notification of the transaction or if your credit or debit card
        or any other valid payment method linked to your FTFTex Account is
        declined.
        <br />
        In the case where we refuse to complete a transaction and/or suspend,
        restrict or close your FTFTex account and/or terminate your use of
        FTFTex services; we will notify you and explain the reasons behind the
        refusal, suspension, restriction, or closure. If appropriate, we will
        provide you with the necessary information to rectify the errors which
        led to the refusal, suspension, restriction, or closure. In the event
        that we refuse to complete a transaction and/or suspend your FTFTex
        Account, we will lift the suspension or complete the transaction as soon
        as reasonably practicable should the reasons that led to the refusal or
        suspension cease to exist. In the case where a transaction has been
        suspended, reversed, or canceled, FTFTex is not obliged to reinstate the
        transaction at the same price or terms.
        <br />
        We may suspend, restrict, or terminate your access to any or all of the
        FTFTex services and/or deactivate or cancel your FTFTex Account without
        reason by giving you notice. You acknowledge that based on confidential
        information and our risk management and security protocols, we may take
        certain actions, including limiting access to, suspending, or closing
        your FTFTex Account. You agree that we are not obliged to disclose
        details of our risk management and security protocols to you.
        <br />
        8.2. Consequences of Termination or Suspension
        <br />
        If we suspend or close your account or terminate your use of FTFTex
        Services for any reason, we reserve the right to require you to
        re-complete the procedures outlined in Section 5.2 (Identity
        Verification) before permitting you to transfer or withdraw Digital
        Assets. You may cancel your FTFTex Account at any time by withdrawing
        all balances. You will not be charged for canceling your FTFTex Account,
        although you will be required to pay any outstanding amounts owed to us.
        <br />
      </p>
      <h3>9. LIABILITY</h3>
      <p>
        9.1. Release of FTFTex
        <br />
        If you have a dispute with one or more users of FTFTex services (other
        than FTFTex), you agree that neither we nor our affiliates or service
        providers, nor any of our directors, employees, and representatives,
        will be liable for any direct or indirect damages, demands or claims of
        any kind or nature that arises from or is connected to such disputes.
        <br />
        9.2. Indemnification
        <br />
        You agree to indemnify FTFTex, our affiliates and service providers, and
        each of our or their respective officers, directors, agents, employees,
        and representatives, in respect of any liabilities, losses, and costs
        (including legal fees and any fines or fees imposed by any regulatory
        authority) that have been reasonably incurred in connection with any
        claims, demands or damages arising out of or related to your breach
        and/or our enforcement of these Terms or any of our Policies, or your
        violation of any law, rule or regulation, or the rights of any
        third-party.
        <br />
        9.3. Limitations of Liability
        <br />
        FTFTex will only be liable to you for loss or damage caused directly and
        reasonably foreseeable by our breach of this Agreement, and our total
        aggregate liability to you for any individual claim or series of
        connected claims shall be limited to a maximum aggregate value of the
        combined value of the E-Funds and Digital Assets in your FTFTex account
        at the time of the relevant claim. When a specific claim relating to a
        specific Digital Asset Transaction is being considered, the claim value
        shall be limited to the purchase/sale of the Digital Asset Transaction
        in dispute.
        <br />
        9.4. Limitation of Loss
        <br />
        In addition to the Limitations of Liability in Section 9.3 above, in no
        event under this Agreement or in connection with this Agreement or
        otherwise shall FTFTex, our affiliates or service providers, or any of
        our or their respective directors, employees, or representatives be
        liable for any of the following types of loss or damage:
        <br />
        1. Any loss of expected or actual revenue or profits, whether directly
        or indirectly, even if we knew or should have known or were advised of
        the same possibility.
        <br />
        2. Any loss of business and/or customers and/or revenue, and/or damage
        to reputation or goodwill whether directly or indirectly, even if we
        were advised or have been made aware of such a possibility.
        <br />
        3. Any loss due to the use of data (including corrupted data), hardware,
        or software, including but not limited to any losses related to Digital
        Asset price data errors, interruptions, or delays in transmission of
        such data.
        <br />
        4. Any loss or damage whatsoever which does not arise directly as a
        result of our breach of this Agreement (whether or not you can prove
        such loss or damage).
        <br />
        9.5. Limitation of liability as permitted by law
        <br />
        The limitation of liability by FTFTex in this section is only to the
        extent permitted by the laws and regulations. In the case of gross
        negligence, deliberate misconduct, fraud, personal injury, or death
        resulting from either our or any of our subcontractors’ negligence,
        nothing in this Agreement shall limit our liability.
        <br />
        9.6. No warranties
        <br />
        To the extent permitted by law, the FTFTex services are provided on an
        "as is" and "as available" basis, with no further promises made by us
        around the availability of the FTFTex services, its suitability for a
        particular purpose, and/or non-infringement. We do not make any promises
        that access to the site, any of the FTFTex services or any of the
        materials contained therein will be continuous, uninterrupted, timely,
        or error-free.
        <br />
        We make no representations about the accuracy, order, timeliness, or
        completeness of historical Digital Asset price data available on the
        site. FTFTex cannot guarantee the processing completion duration, which
        is dependent on factors outside of our control. With respect to your use
        and access to the FTFTex services and site, you hereby acknowledge and
        agree that you have not relied on any statement or understanding
        (written or oral) except those set forth in this Agreement.
        <br />
        9.7. No Liability for Circumstances Beyond Our Control
        <br />
        FTFTex is not liable for any breach of the Agreement, including
        interruption of service or delays, where they arise directly or
        indirectly from abnormal and unforeseeable, and unavoidable
        circumstances beyond our control (“Force Majeure”); nor are we liable
        where the application of any mandatory legal rules have resulted in the
        breach.
        <br />
      </p>
    </>
  );
};

export default ServiceTerms;
