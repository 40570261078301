import {forwardRef} from "react";

export const DropDownHeader = forwardRef(({children, onClick}, ref) => (
    <a
        href=""
        ref={ref}
        onClick={(e) => {
            e.preventDefault();
            onClick(e);
        }}
        className="nav-link d-flex flex-row align-items-center"
    >
        {children}
        <span className="material-symbols-outlined">arrow_drop_down</span>
    </a>
));
