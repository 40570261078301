import {useTranslation} from "react-i18next";
import ApiService from "../../../services/apiService";
import {useRef} from "react";

export const AddAvatarModal = ({userData, getData, onClose}) => {
    const {t} = useTranslation();
    const inputRef = useRef(null);

    const handleImageChange = async (e) => {
        const file = e.target.files[0];
        if (file.name) {
            const name = {
                firstName: userData.firstName,
                lastName: userData.lastName,
                phone: userData.phone,
                email: userData.email,
                service: "register",
                password: userData.password,
                avatar: `https://staging-assets.ftftx.com/${file.name}`,
                fileType: file.type,
            };
            const formData = new FormData();
            formData.append("file", file);
            formData.append("userId", localStorage.getItem("userId"));
            formData.append("name", JSON.stringify(name));
            const response = await ApiService.uploadFile(formData);
            // const data = {
            //   userId: localStorage.getItem("userId"),
            //   avatar: `https://staging-assets.ftftx.com/${file.name}`,
            // };
            // const response1 = await ApiService.changeAvatar(data);
            // if (response1.status === 200) {
            //   if (response.data.url) {
            //     setFtftexValue({
            //       ...ftftexValue,
            //       avatar: response.data.url,
            //     });
            //     enqueueSnackbar(
            //       t("account.community.You successfully updated your avatar"),
            //       {
            //         variant: "success",
            //       }
            //     );
            //   }
            // }
        }
    };

    const handleSave = () => {
        getData();
        onClose();
    }

    return (
        <div className="container p-4">
            <div className="d-flex flex-column align-items-center justify-content-center">
                <div
                    style={{
                        fontSize: 20,
                        fontWeight: 600,
                    }}
                >
                    {t("Profile Picture")}
                </div>
                <div className="d-flex flex-row align-items-center justify-content-between mt-4">
                    <div className="avatar-btns">
                        <div className="col-4 d-flex justify-content-end pointer">
                            <input
                                ref={inputRef}
                                type="file"
                                accept="image/*"
                                onChange={handleImageChange}
                                hidden
                            />
                            <div
                                className="d-flex align-items-center justify-content-center"
                                onClick={() => inputRef.current?.click()}
                            >
                                <span className="material-symbols-outlined mr-2">cloud_upload</span>
                                {t("account.community.Upload")}
                            </div>
                        </div>
                        <div>
                        <span
                            className="material-symbols-outlined align-self-center"
                            style={{fontSize: 55}}
                        >
                  account_circle
                </span>
                        </div>
                    </div>
                </div>
                <div className="d-flex flex-row align-items-center justify-content-between mt-4">
                    <div>
                        <a className="btn btn-outlined mr-3" onClick={onClose}>{t("Cancel")}</a>
                    </div>
                    <div>
                        <a className="btn save-btn mr-3" onClick={handleSave}>{t("account.community.Save")}</a>
                    </div>
                </div>
            </div>
        </div>
    );
}
