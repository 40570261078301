import { useTranslation } from "react-i18next";
import {Spinner} from "react-bootstrap";

const DeleteTradingAccountModal = ({onClose, onContinue, isLoading}) => {
  const {t} = useTranslation();

  return (
      <div className="container p-4">
        <div className="d-flex flex-column align-items-center justify-content-center">
          <h4>{t("account.settings.Delete Trading Account")}</h4>
        </div>
        <div className="red-text mt-lg-3 mb-4" style={{fontSize: 14}}>
          <p>{t("account.settings.You are about to delete your FTFTex Trading Account.")}</p>
          <p>{t("account.settings.The deletion of your account means:")}</p>
          <ul style={{fontSize: 15}}>
            <li>{t("account.settings.Deletion of your KYC information")}</li>
            <li>{t("account.settings.Deletion of your Trading History")}</li>
            <li>{t("account.settings.Deletion of your Transaction History")}</li>
            <li>
              {t("account.settings.You will not be able to deposit")}
            </li>
          </ul>
          <p>
            {t("account.settings.Be sure to withdraw or transfer")}
          </p>
          <div className="d-flex mb-2">
            <div
                className="btn btn-outlined mr-2"
                style={{width: "150px", height: "40px"}}
                onClick={onClose}
            >
              {t("Cancel")}
            </div>
            <div
                className="btn btn-continue"
                style={{width: "150px", height: "40px"}}
                onClick={onContinue}
            >
              {isLoading ? (
                  <Spinner animation="border" variant="primary"/>
              ) : t("Continue")}
            </div>
          </div>
        </div>
      </div>
  );
};

export default DeleteTradingAccountModal;
