export const languageData = [
  {
    id: 1,
    name: "English",
    code: "en",
  },
  {
    id: 2,
    name: "Simplified Chinese (中文)",
    code: "ch",
  },
];
