import { getLoggedIn, getTheme } from "../../../utils";
import { Divider } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useContext, useEffect, useState } from "react";
import { FTFTexContext } from "../../../App";
import { userHiddenEmail } from "./index";
import ApiService from "../../../services/apiService";
import { NavLink, useNavigate } from "react-router-dom";
import { Modal, Spinner } from "react-bootstrap";
import { InputOTPModalEmail } from "./InputOTPModalEmail";
import { FaildAttemptModal } from "./FaildAttemptModal";
import { SuccessfullChangeModal } from "./SuccessfullChangeModal";

export const ChangeEmailSecurity = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const blockedTime = localStorage.getItem("blockPasswordTime");
  const now = new Date().getTime();
  const date1 = new Date(parseInt(blockedTime));
  const date2 = new Date(now);
  const diffInMs = date1 - date2;
  const minutes = Math.floor(diffInMs / 60000);
  const [failedAttempts, setFailedAttempts] = useState(0);
  const [showFaildAttemptModal, setShowFaildAttemptModal] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [newEmail, setNewEmail] = useState("");
  const [currentPassword, setCurrentPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showOTPModal, setShowOTPModal] = useState(false);
  const [DialingCode, setDialingCode] = useState(ApiService.DialingCode);
  const [successfulChangeModal, setSuccessfulChangeModal] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const ftftexValue = useContext(FTFTexContext);
  const email = getLoggedIn()[4];

  useEffect(() => {
    setIsMobile(ftftexValue[0].isMobile);
  }, [ftftexValue[0].isMobile]);

  useEffect(() => {
    if (blockedTime && now < blockedTime) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
      localStorage.removeItem("blockPasswordTimeEmail");
    }
  }, []);

  const editUserEmail = async () => {
    const checkEmail = newEmail.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
    if (failedAttempts === 3) {
      const oneHour = new Date(new Date().getTime() + 60 * 60 * 1000);
      localStorage.setItem(
        "blockPasswordTimeEmail",
        oneHour.getTime().toString()
      );
      setIsDisabled(true);
    } else if (checkEmail !== null) {
      setIsLoading(true);
      const sendEmailResponse = await ApiService.ForgotPassword(
        { email: email },
        "email",
        DialingCode.dialCode
      );
      if (sendEmailResponse.status === 200) {
        setIsLoading(false);
        setShowOTPModal(true);
      }
    } else {
      ftftexValue.toast.error(t("account.security.Please enter a valid email"));
      setIsLoading(false);
    }
  };

  const handleChangePassword = () => {
    localStorage.clear();
    window.location.reload();
    navigate("/login");
    navigate(0);
  };

  return (
    <>
      <div className="container mt-4 mb-4 p-4">
        <div className="pb-2 light-border-bottom">
          <h4
            className={`${
              getTheme() === "dark" ? "sub-title-dark" : "sub-title"
            }`}
          >
            {t("Change Email")}
          </h4>
          <Divider />
        </div>
        <div className="mt-4 mb-4">
          <div className={`row py-2 mt-2 ${isMobile ? "col-12" : "col-10"}`}>
            <div className={isMobile ? "col-5" : "col-4"}>
              <p>{t("account.security.Current Email")}</p>
            </div>
            <div className="col-4">
              <span className="font-weight-bold">{userHiddenEmail(email)}</span>
            </div>
          </div>
          <div className={`row py-2 mt-2 ${isMobile ? "col-12" : "col-10"}`}>
            <div className={isMobile ? "col-5" : "col-4"}>
              <p>{t("account.security.New Email")}</p>
            </div>
            <div className="col-7">
              <div className="d-flex flex-column">
                <input
                  className="form-control w-100"
                  type="text"
                  value={newEmail}
                  onChange={(e) => setNewEmail(e.target.value)}
                />
              </div>
            </div>
          </div>
          {/*<div className={`row py-2 mt-2 ${isMobile ? 'col-12' : 'col-10'}`}>*/}
          {/*    <div className={isMobile ? "col-5" : "col-4"}>*/}
          {/*        <p>{t("account.security.Current Password")}</p>*/}
          {/*    </div>*/}
          {/*    <div className="col-7">*/}
          {/*        <div className="d-flex flex-column">*/}
          {/*            <input*/}
          {/*                className="form-control w-100"*/}
          {/*                type={showPassword ? "text" : "password"}*/}
          {/*                value={currentPassword}*/}
          {/*                onChange={(e) => setCurrentPassword(e.target.value)}*/}
          {/*            />*/}
          {/*        </div>*/}
          {/*    </div>*/}
          {/*    <div className={isMobile ? 'ml-3' : "d-flex flex-row align-items-center show-password"}>*/}
          {/*        <input*/}
          {/*            type="checkbox"*/}
          {/*            id="exampleCheck1"*/}
          {/*            checked={showPassword}*/}
          {/*            onChange={() => setShowPassword(!showPassword)}*/}
          {/*        />*/}
          {/*        <label className="show-password-label"*/}
          {/*               htmlFor="exampleCheck1">{t("account.security.Show Password")}</label>*/}
          {/*    </div>*/}
          {/*</div>*/}
          {isDisabled ? (
            <span className="align-self-center mt-4">
              {t("account.security.You can change your password only after")}
              {` ${minutes} minutes`}
            </span>
          ) : (
            <div className="d-flex mb-2 mt-4">
              <div
                className={`btn save-btn ${
                  isMobile ? "confirm-btn-mobile" : "confirm-btn"
                }`}
                onClick={editUserEmail}
              >
                {isLoading ? (
                  <Spinner animation="border" variant="primary" />
                ) : (
                  t("Confirm")
                )}
              </div>
            </div>
          )}
        </div>
        <button className="btn btn-primary px-5 d-block mt-5 back-btn">
          <NavLink to={"/account/security"}>{t("Back")}</NavLink>
        </button>
      </div>
      <Modal show={showOTPModal} onHide={() => setShowOTPModal(false)} centered>
        <InputOTPModalEmail
          onClose={() => setShowOTPModal(false)}
          email={email}
          newEmail={newEmail}
          setSuccessfulChangeModal={setSuccessfulChangeModal}
          setFailedAttempts={setFailedAttempts}
          failedAttempts={failedAttempts}
          setShowFaildAttemptModal={setShowFaildAttemptModal}
        />
      </Modal>
      <Modal
        show={showFaildAttemptModal}
        onHide={() => setShowFaildAttemptModal(false)}
        centered
      >
        <FaildAttemptModal
          handleClose={() => setShowFaildAttemptModal(false)}
          show={showFaildAttemptModal}
          faildAttemps={failedAttempts}
        />
      </Modal>
      <Modal
        show={successfulChangeModal}
        onHide={() => setSuccessfulChangeModal(false)}
        centered
      >
        <SuccessfullChangeModal
          handleClose={handleChangePassword}
          isEmail={true}
        />
      </Modal>
    </>
  );
};
