import {getLoggedIn, getTheme} from "../../../utils";
import {Divider} from "@mui/material";
import {useTranslation} from "react-i18next";
import {Card} from "react-bootstrap";
import {NavLink} from "react-router-dom";
import {useContext, useEffect, useState} from "react";
import {FTFTexContext} from "../../../App";

export const CheckVerification = () => {
    const {t} = useTranslation();
    const ftftexValue = useContext(FTFTexContext);
    const [isMobile, setIsMobile] = useState(false);
    const [isVerified, setIsVerified] = useState(false);
    const points = [
        "Personal information",
        "Government ID",
        "Facial recognition"
    ];

    useEffect(() => {
        const data = getLoggedIn();
        setIsVerified(data[6] === 'verified');
    }, []);


    useEffect(() => {
        setIsMobile(ftftexValue[0].isMobile);
    }, [ftftexValue[0].isMobile]);

    return (
        <div className="container mt-4 mb-4 p-4">
            <div className="pb-2 light-border-bottom">
                <h4 className={`${getTheme() === 'dark' ? 'sub-title-dark' : 'sub-title'}`}>
                    {t("Account Verification")}
                </h4>
                <Divider/>
            </div>
            <div className={isMobile ? "tier-rectangle-mobile" : "tier-rectangle"} style={{backgroundColor: isVerified ? '#0020CE' : '#C5C8C9'}}>
                <div className="tier">
                    <div className="tier-title">Tier 1</div>
                    {isVerified ? <div style={{color: '#06927E'}}>Verified</div> :
                        <div style={{color: 'red'}}>Not Verified</div>}
                </div>
            </div>
            <div className="tier-wrapper">
                <Card className="tier-card">
                    <Card.Body className={isMobile ? "tier-card-body-mobile": "tier-card-body"}>
                        {points.map((point, index) => (
                            <div key={index} className="tier-card-point">
                                {point}
                                {isVerified ?
                                    <div className="dot-verify">
                                    <span className="material-symbols-outlined"
                                          style={{fontSize: '18px', color: 'whitesmoke'}}>
                                    done
                                    </span>
                                    </div> : <div className="dot-not-verify"/>
                                }
                            </div>
                        ))}
                        {!isVerified && <button className="btn btn-primary px-3 d-block back-btn verify-btn">
                            <NavLink to={"/account/verification"}>{t("Verify")}</NavLink>
                        </button>}
                    </Card.Body>
                </Card>
            </div>
            <button className="btn btn-primary px-5 d-block mt-5 back-btn">
                <NavLink to={"/account/security"}>{t("Back")}</NavLink>
            </button>
        </div>
    )
}
