export const headerNav = [
  {
    id: 1,
    name: "Home",
    path: "/",
    subMenu: [],
  },
  {
    id: 2,
    name: "Markets",
    path: "",
    subMenu: [
      {
        id: 1,
        name: "Currencies",
        path: "/coins",
      },
      {
        id: 2,
        name: "Exchanges",
        path: "/exchanges",
      },
    ],
  },
  {
    id: 3,
    name: "Learn",
    path: "",
    subMenu: [
      {
        id: 1,
        name: "News",
        path: "/news",
      },
      // {
      //     id: 2,
      //     name: 'Resources',
      //     path: '/resources'
      // }
    ],
  },
  {
    id: 4,
    name: "Community",
    path: "/community/feed",
    subMenu: [],
  },
  // {
  //     id: 5,
  //     name: 'Help Center',
  //     path: 'https://faq.ftftx.com/',
  //     subMenu: []
  // }
];

