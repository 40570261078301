import { useTranslation } from "react-i18next";
import "./index.scss";
import AccountSettings from "./AccountSettings";
import TradingSettings from "./TradingSettings";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import DeleteTradingAccountModal from "./DeleteTradingAccountModal";
import {getLoggedIn} from "../../../utils";
import ApiService from "../../../services/apiService";
import {DeleteAccountOTP} from "./DeleteAccountOTP";
import {ConfirmDeletionModal} from "./ConfirmDeletionModal";

const SettingsAccount = () => {
    const {t} = useTranslation();
    const [isDeleteTradingAccount, setIsDeleteTradingAccount] = useState(false);
    const [isOTPModal, setIsOTPModal] = useState(false);
    const [isSuccessOTP, setIsSuccessOTP] = useState(false);
    const [resultMessage, setResultMessage] = useState("");
    const email = getLoggedIn()[4];
    const [isLoading, setIsLoading] = useState(false);
    const [DialingCode, setDialingCode] = useState(ApiService.DialingCode);

    const handleCloseModal = () => {
        setIsDeleteTradingAccount(false);
    };

    const sendOTP = async () => {
        setIsLoading(true);
        const sendEmailResponse = await ApiService.ForgotPassword(
            {email: email},
            "email",
            DialingCode.dialCode
        );
        if (sendEmailResponse.status === 200) {
            setIsOTPModal(true);
            setIsDeleteTradingAccount(false);
            setIsLoading(false);
        }
    }

    return (
        <div className="container mt-4 mb-4 p-4">
            <AccountSettings />
            <TradingSettings />
            <div
                className="d-flex justify-content-end"
                onClick={() => setIsDeleteTradingAccount(true)}
            >
                <a className="btn btn-outlined mr-3 mt-4">
                    {t("account.settings.Delete Trading Account")}
                </a>
            </div>
            <Modal
                show={isDeleteTradingAccount}
                onHide={handleCloseModal}
                centered
                size="lg"
            >
                <DeleteTradingAccountModal onClose={handleCloseModal} onContinue={sendOTP} isLoading={isLoading}/>
            </Modal>
            <Modal show={isOTPModal} onHide={() => setIsOTPModal(false)} centered >
                <DeleteAccountOTP onClose={() => setIsOTPModal(false)} setResultModal={() => setIsSuccessOTP(true)}
                                  setResultMessage={setResultMessage}/>
            </Modal>
            <Modal show={isSuccessOTP} onHide={() => setIsSuccessOTP(false)} centered>
                <ConfirmDeletionModal onClose={() => setIsSuccessOTP(false)} result={resultMessage}/>
            </Modal>
        </div>
    );
};

export default SettingsAccount;
